import React, { Suspense, lazy } from "react";
import "style.css";
import "tailwindcss/lib/css/preflight.css";
import AnimationRevealPage from "helpers/AnimationRevealPage";

// Lazy load components
const Hero = lazy(() => import("components/hero/aoa_BackgroundAsImageWithCenteredContent"));
const About = lazy(() => import("components/features/aoa_TwoColSingleFeatureWithStats2"));
const Services = lazy(() => import("components/pricing/aoa_services_ThreePlansWithHalfPrimaryBackground"));
const Contact = lazy(() => import("components/forms/aoa_TwoColContactUsWithIllustrationFullForm"));
const Safety = lazy(() => import("components/features/aoa_safety_TwoColWithTwoHorizontalFeaturesAndButton"));
const Environment = lazy(() => import("components/features/aoa_env_TwoColWithTwoHorizontalFeaturesAndButton"));
const Footer = lazy(() => import("components/footers/aoa_MiniCenteredFooter"));

function App() {
  return (
    <>
      <Suspense fallback={<div>Loading...</div>}>
      <AnimationRevealPage>
        {/* Use Suspense to wrap lazy-loaded components */}
          <Hero />
          <About />
          <Services />
          <Safety />
          <Environment />
          <Contact />
          <Footer />
      </AnimationRevealPage>
      </Suspense>
    </>
  );
}

export default App;
